.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

:root {
  --dark-color-a: #667aff;
  --dark-color-b: #7386ff;
  --light-color: #e6e9ff;
  --success-color: #5cb85c;
  --error-color: #d9534f;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  background: var(--light-color);
  margin: 20px;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.btn {
  cursor: pointer;
  padding: 5px 15px;
  background: var(--light-color);
  color: var(--dark-color-a);
  border: 0;
  font-size: 17px;
}

/* Chat Page */

.chat-container {
  max-width: 1100px;
  background: #fff;
  margin: 30px auto;
  overflow: hidden;
}

.chat-header {
  background: var(--dark-color-a);
  color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-main {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.chat-sidebar {
  background: var(--dark-color-b);
  color: #fff;
  padding: 20px 20px 60px;
  overflow-y: scroll;
}

.chat-sidebar h2 {
  font-size: 20px;
  background: rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-bottom: 20px;
}

.chat-sidebar h3 {
  margin-bottom: 15px;
}

.chat-sidebar ul li {
  padding: 10px 0;
}

.chat-messages {
  display: flex;
  flex-direction: column;
  padding: 30px;
  max-height: 500px;
  overflow-y: scroll;
}

.chat-messages .message {
  padding: 10px;
  margin-bottom: 15px;
  background-color: var(--light-color);
  border-radius: 5px;
}

.chat-messages .message .meta {
  font-size: 15px;
  font-weight: bold;
  color: var(--dark-color-b);
  opacity: 0.7;
  margin-bottom: 7px;
}

.chat-messages .message .meta span {
  color: #777;
}

.chat-form-container {
  padding: 20px 30px;
  background-color: var(--dark-color-a);
}

.chat-form-container form {
  display: flex;
}

.chat-form-container input[type="text"] {
  font-size: 16px;
  padding: 5px;
  height: 40px;
  flex: 1;
}

/* Join Page */
.join-container {
  max-width: 500px;
  margin: 80px auto;
  color: #fff;
}

.join-header {
  text-align: center;
  padding: 20px;
  background: var(--dark-color-a);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.join-main {
  padding: 30px 40px;
  background: var(--dark-color-b);
}

.join-main p {
  margin-bottom: 20px;
}

.join-main .form-control {
  margin-bottom: 20px;
}

.join-main label {
  display: block;
  margin-bottom: 5px;
}

.join-main input[type="text"] {
  font-size: 16px;
  padding: 5px;
  height: 40px;
  width: 100%;
}

.join-main select {
  font-size: 16px;
  padding: 5px;
  height: 40px;
  width: 100%;
}

.join-main .btn {
  margin-top: 20px;
  width: 100%;
}

@media (max-width: 700px) {
  .chat-main {
    display: block;
  }

  .chat-sidebar {
    display: none;
  }
}
